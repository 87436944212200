import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import firebase from 'firebase';

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyBPVWYaxRkCMHHPcfBMud3v2EZU5x0klEs",
//   authDomain: "pardi-ppl.firebaseapp.com",
//   projectId: "pardi-ppl",
//   storageBucket: "pardi-ppl.appspot.com",
//   messagingSenderId: "331700756391",
//   appId: "1:331700756391:web:d1a473f91bf085e981e082",
//   measurementId: "G-XR7PMGPH93",
// });


// const ref = app.storage().ref('sponsor/');
// ref.listAll().then(function(result) {
//   result.items.forEach(function(imageRef) {
//     // And finally display them
//     // imageRef.getMetadata().then(data=>{
//     //   console.log(data);
//     // })
//     imageRef.updateMetadata({cacheControl: 'max-age=31536000'}).then(data=>{
//       console.log(data)
//     }).catch(e => {
//       console.log(e)
//     })
//   });
// }).catch(function(error) {
//   console.log('error', error)
//   // Handle any errors
// });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
