import React from 'react';
import './preload.css';
import cb from '../../assets/images/cricketball.gif';

export default function preload() {

  return (
      <div className="container preload">
        <img src={cb} alt="Loading..."/>
      </div>
  )
}