import React, { useEffect, lazy, Suspense } from 'react';
import './App.css';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Preload from './Components/Preloader/preload';


const Home = lazy(() => import('./Pages/Home'));
const Teams = lazy(() => import('./Pages/Teams'));
const Schedule = lazy(() => import('./Pages/Schedule'));
const Rules = lazy(() => import('./Pages/Rules'));

function App() {
  const doubleClick = (e) => {
    e.preventDefault();
  }
  useEffect(() => {
    document.addEventListener('contextmenu', doubleClick);
    return () => {
      document.removeEventListener('contextmenu', doubleClick)
    }
  }, [])
  return (
    <Router>
      <Suspense fallback={<Preload/>}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/teams" component={Teams} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/rules" component={Rules} />
            <Route path="*" component={Home} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
